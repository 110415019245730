<template>
  <NuxtLoadingIndicator style="position: sticky;" />
  <div class="background">
    <NuxtLink
      to="/acheter/annonces"
      aria-label="Revenir à l'accueil"
      class="d-none d-md-inline-block back"
    >
      <span class="arrow">&larr;</span>
      Revenir à l'accueil
    </NuxtLink>

    <div
      v-if="propertyData"
      class="container"
    >
      <HCard
        color="white"
        class="panel"
      >
        <div class="property-block">
          <SvgLogoBlue class="logo mb-4" />

          <div>
            <div class="property-img">
              <VImg
                :src="propertyData?.pictures?.[0].url"
                class="mb-4"
              />
            </div>
            <div class="mb-4">
              <p>{{ propertyData?.address || propertyData?.lead && propertyData?.lead?.estate?.address?.street }}</p>
              <p>{{ propertyData?.city?.zipcode }} {{ upperFirst(propertyData?.city?.name) }}</p>
            </div>
            <div class="mb-4">
              <p>{{ upperFirst(propertyData?.type) }}</p>
              <p>
                {{ propertyData?.rooms }} {{ propertyData?.rooms > 1 ? 'Pièces' : 'Pièce' }} -
                {{ Math.round(propertyData?.area?.value || propertyData?.area?.total) }} m²
              </p>
            </div>
            <div class="property-price my-4">
              {{ price }} €
            </div>
          </div>

          <HButton
            block
            tag="link"
            target="_blank"
            :to="'/acheter/annonces/' + propertyUrl"
            color="pink"
          >
            Consulter le descriptif du bien
          </HButton>
        </div>

        <div class="content-block">
          <slot />
        </div>
      </HCard>
    </div>
  </div>
</template>

<script lang="ts" setup>
import upperFirst from 'lodash/upperFirst'

const route = useRoute()

const propertyUrl = ref('')

const { data: propertyData } = useAsyncData(
  'propertyData',
  async () => {
    // the property id is either provided as a param
    // or it must be the last element of the path, separated by a dash or a slash
    let id = route.params.propertyId ?? route.path.split(/-|\//).pop()
    if (Array.isArray(id)) { id = id[0] }

    let provider = 'apimo'
    let _propertyData: any = {}

    if (id) {
      if (id.startsWith('r')) {
        id = id.replace('r', '')
        provider = 'reselling'
      }

      if (!parseInt(id)) {
        throw createError({ statusCode: 404, message: 'Post not found' })
      }
    } else {
      throw createError({ statusCode: 404, message: 'Post not found' })
    }

    // Property data
    try {
      switch (provider) {
        case 'reselling':
          _propertyData = await getService('resellings-infos').get(id)
          _propertyData.isReselling = true
          break

        default:
          _propertyData = await useHFetch('apimo/properties/' + id)
          _propertyData = _propertyData.data.value
          break
      }
    } catch (e: any) {
      console.log(e.response)
    }

    if (!_propertyData) {
      throw createError({ statusCode: 404, message: 'Post not found' })
    }

    if (_propertyData.removed) {
      let region: any = null
      switch (_propertyData.lead_owner?.branch?.name) {
        case 'Paris':
          region = 'paris-ile-de-france'
          break
        default:
          region = _propertyData.lead_owner?.branch?.name?.toLowerCase()
      }
      if (region) {
        navigateTo(`/acheter/annonces/region/${region}`, { redirectCode: 301 })
      }
    }

    propertyUrl.value = 'vente-' + _propertyData?.type?.toLowerCase() + '-' + _propertyData?.rooms + '-pieces-' + parseInt(_propertyData?.area?.value) + 'm2-' + _propertyData?.city?.name?.toLowerCase() + '-' + _propertyData?.city?.zipcode + '-' + id

    return _propertyData
  }
)

const price = computed(() => {
  return numberWithSpace(Math.round(propertyData.value?.price?.value))
})

provide('propertyData', propertyData)
provide('propertyUrl', propertyUrl)

</script>

<style lang="scss" scoped>
.container {
  padding: 10px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.background {
  background-color: $purple;
  min-height: 100vh;

  .back {
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    font-size: 12px;
  }
}

.panel.card.white {
  padding: 0;
  color: $night-blue;
  display: flex;
  flex-direction: row;

   @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
    border-radius: 25px;
  }

  @include media-breakpoint-down(sm) {
    border-radius: 0;
  }

  .property-block {
    padding: 30px;
    border-right: 3px solid #4C00FF33;
    width: 33%;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-breakpoint-down(md) {
      width: 100%;
      border-right: 0;
      border-top: 3px solid #4C00FF33;
    }

    .logo {
      width: calc(min(75%, 250px));

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .property-price {
      font-size: 1.5em;
      font-weight: bold;
      color: $purple;
    }
  }
}

.content-block {
  padding: 30px;
  flex-grow: 1;
}
</style>
